//Subclass of AbstractServiceOrderCtrl
//I know it isn't inheritance, it is composition.
//But who cares in JavaScript:)
app.controller('EditServiceOrderCtrl', [
  '$scope', '$controller', '$routeParams', '$location', 'fixRailsSerializer', 'Customer', 'Company', 'Client', 'ServiceOrder', 'WorkTimeEstimate', 'PickupLocation',
  function($scope, $controller, $routeParams, $location, fixRailsSerializer, Customer, Company, Client, ServiceOrder, WorkTimeEstimate, PickupLocation) {
    $controller('AbstractServiceOrderCtrl', { $scope, $routeParams, $location, fixRailsSerializer, Customer, Client, ServiceOrder, WorkTimeEstimate });

    $scope.pickup_locations = PickupLocation.query();
    $scope.bill_strategies = ['Normal', 'Recall'];

    Company.query().then(function(list) {
      $scope.companies = list;
    })

    ServiceOrder.get({id: $routeParams['service_order_id']}).then(
      function(resource){
      $scope.service_order= resource;
      $scope.needs_pickup = !!$scope.service_order.pickup_location_id;

      //TODO: remove following code when ngOptions track by work.
      Client.query().then(function set_service_order_client_from_clients_array_so_ng_options_would_work(resource){
        $scope.clients = resource;
        angular.forEach($scope.clients, function(client, key){ if(client.id == $scope.service_order.client.id) {$scope.service_order.client=client;}});
      });
    });

    $scope.save_service_order = function()
    {
      function disable_submit()
      {
        $('button[data-loading-text]').button('loading');
      }

      function enable_submit()
      {
        $('button[data-loading-text]').button('reset');
      }

      function visit_service_order_page()
      {
        window.location = '/service_orders/' + $scope.service_order.id;
      }

      if( $scope.service_order.client )
        $scope.service_order.client_id = $scope.service_order.client.id;

      delete $scope.service_order.client;
      delete $scope.service_order.items;
      delete $scope.service_order.followups;
      delete $scope.service_order.number;
      delete $scope.service_order.schedule_request;
      delete $scope.service_order.customer;
      if(! $scope.needs_pickup){
  	$scope.service_order.pickup_location_id = null;
        }

      disable_submit();

      $scope.service_order.save().then( visit_service_order_page, enable_submit );
    };
  }
]);
